export const COLORS = {
  "light-blue": "#D5FEFF",
  teal: {
    100: "#D7FAFB",
    300: "#D6F4F5",
    500: "#218285",
    700: "#0A585B",
    900: "#08484A",
  },
  charcoal: {
    700: "#39536A",
  },
  "slate-gray": "#114948",
  slate: {
    100: "#F3F6F6",
    200: "#E0EAEB",
    300: "#C2D5D6",
    500: "#708D8E",
    700: "#567576",
    900: "#153132",
  },
  purple: "#A36CCD",
  red: "#FD4848",
  green: "#5FB900",
  rose: {
    100: "#FFE2E2",
    300: "#FFE5E6",
    500: "#EE4E82",
    700: "#BC0003",
    800: "#BC0003",
  },
  lime: {
    100: "#DCFFB7",
    500: "#4C7566",
    800: "#006811",
  },
  blue: "#0052FF",
  cobalt: {
    700: "#022995",
    300: "#A8B8F0",
  },
  stone: {
    100: "#F7F8FA",
    300: "#EAECF2",
    700: "#848B9E",
    900: "#021341",
  },
  sky: {
    300: "#CAEDFF",
  },
};
