import { atom } from "jotai";

export const showInputBox = atom(false);

export const usersAtom = atom([
  {
    user_name: "User1",
    user_id: "40e340ed-dd12-48f6-96df-660e4a3f3ac2",
    test_result: {
      leu: "0 leu/μL",
      nit: "Negative",
      uro: ".2 mg/dL",
      pro: "0 mg/dL",
      ph: "5.0",
      blo: "0 Ery/μL",
      sg: "1.000",
      ket: "0 mg/dL",
      bil: "0 mg/dL",
      glu: "0 mg/dL",
    },
  },
  {
    user_name: "User2",
    user_id: "9c127415-4d4f-4481-8f02-86df408baf3a",
    test_result: {
      leu: "15 leu/μL",
      nit: "Positive ",
      uro: ".2 mg/dL",
      pro: "0 mg/dL",
      ph: "5.0 ",
      blo: "0 Ery/μL",
      sg: "1.000 ",
      ket: "40 mg/dL",
      bil: "0 mg/dL",
      glu: "0 mg/dL",
    },
  },
]);
