import { QueryClientProvider } from "@tanstack/react-query";
import NoSleep from "nosleep.js";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import { queryClient } from "./queryClient";
import { router } from "./routes/routes";

const noSleep = new NoSleep();

function App() {
  useEffect(() => {
    document.addEventListener(
      "click",
      function enableNoSleep() {
        document.removeEventListener("click", enableNoSleep, false);
        noSleep.enable();
      },
      false
    );
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
