import { IoClose } from "react-icons/io5";
import { Button } from "../ui/button";
import { X } from "lucide-react";

type ChatHeaderProps = {
  isChatOpen: boolean;
  setIsChatOpen: (isChatOpen: boolean) => void;
};

const ChatHeader = ({ isChatOpen, setIsChatOpen }: ChatHeaderProps) => {
  return (
    <div className="relative flex items-center mt-4">
      <span className="text-primary font-bold mx-auto text-[1rem]">
        Ribbon Support
      </span>
      <Button
        variant="ghost"
        size="icon"
        onClick={() => setIsChatOpen(!isChatOpen)}
        className="text-primary absolute right-0"
      >
        <X size={30} />
      </Button>
    </div>
  );
};

export default ChatHeader;
