import BotImage from "@/assets/img/de1b3c7ec885da63d0fc95324d23fbcd.png";

const ChatBubbleTyping = () => {
  return (
    <div className="flex justify-start items-start">
      <img
        className="w-8 h-8 rounded-lg mr-2"
        src={BotImage}
        alt="Chat bot image"
      />
      <div
        className={`flex flex-col w-full max-w-[20%] min-h-[52px] items-center bg-primary-3 rounded-e-xl rounded-es-xl justify-center`}
      >
        <div className="flex items-center space-x-1">
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className="w-1 h-1 rounded-full bg-primary animate-bounce"
              style={{ animationDelay: `${index * 0.2}s` }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatBubbleTyping;
