import { COLORS } from "@/utils/colors";
import { SplashColors } from "@/types/common";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import posthog from "posthog-js";
import { supabaseService } from "@/services/supabaseService";
import { toast } from "sonner";
import { useAtom, useSetAtom } from "jotai";
import { resetMessagesAtom } from "@/store/atoms/messageAtom";
import { showInputBox } from "@/store/atoms/userAtom";

interface IAppContext {
  splashColors: SplashColors;
  setSplashColors: (colors: SplashColors) => void;
  headerContent: ReactNode;
  setHeaderContent: (content: () => ReactNode) => void;
  isNavigationInteracted: boolean;
  isLoggedIn: boolean;
  session: any;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  logOut: () => Promise<void>;
}

const defaultValue: IAppContext = {
  splashColors: {
    background: COLORS["light-blue"],
    logo: COLORS.cobalt["700"],
  },
  setSplashColors: () => {},
  headerContent: null,
  setHeaderContent: () => {},
  isNavigationInteracted: false,
  isLoggedIn: false,
  session: undefined,
  sidebarOpen: false,
  setSidebarOpen: () => {},
  logOut: async () => {},
};

const AppContext = createContext(defaultValue);

export function AppContextProvider({ children }: { children?: ReactNode }) {
  const supabase = supabaseService.getClient();
  const sessionLoaderData: any = useLoaderData();
  const [session, setSession] = useState(sessionLoaderData);
  const [splashColors, setSplashColors] = useState(defaultValue.splashColors);
  const [headerContent, setHeaderContent] = useState<any>(
    defaultValue.headerContent
  );
  const [initialLocationPathname] = useState(window.location.pathname);
  const [isNavigationInteracted, setIsNavigationInteracted] = useState(
    defaultValue.isNavigationInteracted
  );
  const [sidebarOpen, setSidebarOpen] = useState(defaultValue.sidebarOpen);
  const location = useLocation();
  const isLoggedIn = !!session?.user;
  const navigate = useNavigate();
  const resetMessages = useSetAtom(resetMessagesAtom);
  const [, setShowInputBoxFlag] = useAtom(showInputBox);

  const logOut = useCallback(async () => {
    const supabase = supabaseService.getClient();
    const { error } = await supabase.auth.signOut();

    if (error) {
      const message = error.message || "Error";
      toast.error(message);
    } else {
      resetMessages();
      setShowInputBoxFlag(false);
      navigate(".", { replace: true });
      toast.success("Successfully logged out");
    }
  }, [navigate]);

  useEffect(() => {
    setSession(sessionLoaderData);
  }, [sessionLoaderData]);

  useEffect(() => {
    if (location.pathname !== initialLocationPathname) {
      setIsNavigationInteracted(true);
    }
  }, [initialLocationPathname, location.pathname]);

  useEffect(() => {
    const env = process.env.REACT_APP_ENV ?? "development";

    if (["prod", "stg"].includes(env)) {
      posthog.capture("$pageview");
    }
  }, [location]);

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
    });
  }, [supabase.auth]);

  return (
    <AppContext.Provider
      value={{
        splashColors,
        setSplashColors,
        headerContent,
        setHeaderContent,
        isNavigationInteracted,
        isLoggedIn,
        session,
        sidebarOpen,
        setSidebarOpen,
        logOut,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
